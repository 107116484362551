import React from 'react';
import utils from './utils';

const Inscription = React.lazy(() => new Promise(async resolve => {
    const excursionBasicInfos = await utils.getExcursionBasicInfos();
    console.log({ excursionBasicInfos });
    if (excursionBasicInfos.hasOwnProperty('error')) {
        if (excursionBasicInfos.error === 'excursion delay expired') {
            window.location.assign('/registration-expired');
        } else if (excursionBasicInfos.error === 'too much participants') {
            window.location.assign('/registration-participants-error');
        } else {
            console.log(excursionBasicInfos.error);
            window.location.assign('/registration-fail');
        }
    } else {
        window.EXCURSION_NAME = excursionBasicInfos.success.excursionName;
        window.EXCURSION_maxParticipantsNumber = excursionBasicInfos.success.maxParticipantsNumber;
        window.EXCURSION_participantsNumber = excursionBasicInfos.success.participantsNumber;
        resolve(await import('./Pages/Authentication/SignIn/Inscription'));
    }
}));

const InscriptionSuccess = React.lazy(() => new Promise(async resolve => {
    const excursionBasicInfos = await utils.getExcursionBasicInfos();
    if (excursionBasicInfos.hasOwnProperty('error')) {
        console.log(excursionBasicInfos.error);
        window.location.assign('/registration-fail');
    } else {
        window.EXCURSION_NAME = excursionBasicInfos.success.excursionName;
        resolve(await import('./Pages/Authentication/SignIn/InscriptionSuccess'));
    }
}));

const InscriptionFail = React.lazy(() => new Promise(async resolve => {
    resolve(await import('./Pages/Authentication/SignIn/InscriptionFail'));
}));

const InscriptionError = React.lazy(() => new Promise(async resolve => {
    resolve(await import('./Pages/Authentication/SignIn/InscriptionError'));
}));

const InscriptionExpired = React.lazy(() => new Promise(async resolve => {
    resolve(await import('./Pages/Authentication/SignIn/InscriptionExpired'));
}));

const InscriptionTooMuchParticipants = React.lazy(() => new Promise(async resolve => {
    resolve(await import('./Pages/Authentication/SignIn/InscriptionTooMuchParticipants'));
}));

const SignIn2 = React.lazy(() => new Promise(async resolve => {
    if (await utils.isAuthenticated()) await utils.logout();
    resolve(await import('./Pages/Authentication/SignIn/SignIn2'));
}));

const SignUp2 = React.lazy(() => new Promise(async resolve => {
    if (await utils.isAuthenticated()) await utils.logout();
    resolve(await import('./Pages/Authentication/SignUp/SignUp2'));
}));

const ChangePassword = React.lazy(() => new Promise(async resolve => {
    if (await utils.isAuthenticated()) resolve(await import('./Pages/Authentication/ChangePassword'));
    else window.location.assign('/auth/signin');
}));

const ProfileSettings = React.lazy(() => new Promise(async resolve => {
    if (await utils.isAuthenticated()) resolve(await import('./Pages/Authentication/ProfileSettings'));
    else window.location.assign('/auth/signin');
}));

const mainRoutes = [
    { path: '/registration', exact: true, name: 'Inscription', component: Inscription },
    { path: '/registration-success', exact: true, name: 'InscriptionSuccess', component: InscriptionSuccess },
    { path: '/registration-fail', exact: true, name: 'InscriptionFail', component: InscriptionFail },
    { path: '/registration-error', exact: true, name: 'InscriptionError', component: InscriptionError },
    { path: '/registration-expired', exact: true, name: 'InscriptionExpired', component: InscriptionExpired },
    { path: '/registration-participants-error', exact: true, name: 'InscriptionTooMuchParticipants', component: InscriptionTooMuchParticipants },
    { path: '/auth/signup', exact: true, name: 'SignUp', component: SignUp2 },
    { path: '/auth/signin', exact: true, name: 'SignIn', component: SignIn2 },
    { path: '/auth/change-password', exact: true, name: 'Changer le mot de passe', component: ChangePassword },
    { path: '/auth/profile-settings', exact: true, name: 'Paramètres de profil', component: ProfileSettings }
];

export default mainRoutes;
